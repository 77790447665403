@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Add this to your CSS file */
@media (max-width: 768px) { /* or whatever breakpoint you choose for mobile devices */
    .karma-modal-class {
        position: fixed; /* or absolute */
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 1000; /* make sure this is higher than other elements */
        overflow-y: auto; /* for scrolling */
    }

    .karma-modal-background-class {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5); /* Dimmed background */
    }

    .karma-modal-content-class {
        margin: 0;
        border-radius: 0; /* Optional: removes border-radius for full screen */
        height: 100vh;
    }
}
